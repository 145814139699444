import '@babel/polyfill';
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import 'sidebar-v2/css/ol3-sidebar.css';

import * as basicLightbox from 'basiclightbox'
import 'basiclightbox/dist/basicLightbox.min.css';

import {
	Map,
	View
} from 'ol';
import {
	Group as LayerGroup
} from 'ol/layer.js';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ.js';
import VectorLayer from 'ol/layer/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
//import OSM from 'ol/source/OSM';
import OSM, {
	ATTRIBUTION
} from 'ol/source/OSM.js';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector.js';
import {
	fromLonLat
} from 'ol/proj.js';
import {
	Fill,
	Stroke,
	Style
} from 'ol/style.js';
import {
	defaults as defaultControls,
	ScaleLine,
	Control
} from 'ol/control.js';

// Custom version of ol-hashed that preserves layer visibility
// import sync from './ol-hashed-IBB.js';


window.onload = async function() {
async function qr2() {
	const QRCode = await import('qrcode');
	console.log("QR for ",window.location.href);
	QRCode.toCanvas(document.getElementById('qr-canvas'), window.location.href, function (error) {
		if (error) console.error(error);
	})
};
// Let this snippet run before your hashchange event binding code
if (!window.HashChangeEvent)(function(){
  var lastURL = document.URL;
  window.addEventListener("hashchange", function(event){
    Object.defineProperty(event, "oldURL", {enumerable:true,configurable:true,value:lastURL});
    Object.defineProperty(event, "newURL", {enumerable:true,configurable:true,value:document.URL});
    lastURL = document.URL;
  });
}());
function qr() {
	console.log("TEST");
}
window.addEventListener('popstate', qr, false);
window.addEventListener('hashchange', qr, false);



	const LayerSwitcher = await import('ol-layerswitcher');
	// Custom version of ol-hashed that preserves layer visibility
	//const sync = await import('./ol-hashed-IBB.js');
	// Custom version of sidebar-v2 that works with ol5 stuff and plays nice with ol-hashed
	const SideBar = await import('./ol3-sidebar-IBB.js');

	var layersList = [];

	// #### Layers will be automagically inserted here. See base_layers.js to add static layers.

// H I L L S H A D E
/*var lyr_hillshade = new TileLayer({
	title: "Hillshade",
	preload: Infinity,
	opacity: 1,
	visible: true,
	declutter: true,
	projection: 'EPSG:3857',
	extent: [797674.255, 6657288.972, 807468.724, 6667109.318],
	source: new XYZ({
		url: '/layers/Hillshade/{z}/{x}/{-y}.png'
	})
});
*/

// M O D E L L G R E N Z E
var lyr_Modellgrenze = new VectorLayer({
	visible: true,
	declutter: true,
	updateWhileAnimating: true,
	source: new VectorSource({
		url: 'layers/Gemeindegrenzen_EPSG_3857.geojson',
		format: new GeoJSON()
	}),
	style: new Style({
		fill: new Fill({color: 'white'}),
		stroke: new Stroke({
			color: 'rgba(0,0,0,1.0)',
			lineDash: [10, 5],
			lineCap: 'square',
			lineJoin: 'bevel',
			width: 2
		}),
	}),
//	title: '<img src="/legend/Modellgrenze.png"/> Gemeindegrenzen'
//	title: 'Gemeindegrenzen'
});
lyr_Modellgrenze.setZIndex(1000);

// G E W
var lyr_GEW = new VectorLayer({
	visible: true,
	declutter: true,
	updateWhileAnimating: true,
	source: new VectorSource({
		url: 'layers/GEW_clipped.geojson',
		format: new GeoJSON()
	}),
	style: new Style({
		fill: new Fill({color: 'cyan'}),
		stroke: new Stroke({
			color: 'rgba(0,0,0,1.0)',
			width: 1
		}),
	}),
	title: '<i class="fa fa-water"></i> Gewässerflächen'
});
lyr_GEW.setZIndex(15);
/*
// G E W A E S S E R
var lyr_Gewaesser = new VectorLayer({

	visible: true,
	declutter: true,
	source: new VectorSource({
		url: 'layers/Gewaesser.geojson',
		format: new GeoJSON()
	}),
	style: new Style({
		stroke: new Stroke({
			color: 'rgba(0,0,255,1.0)',
			lineDash: null,
			lineCap: 'butt',
			lineJoin: 'miter',
			width: 0
		}),
		fill: new Fill({
			color: 'rgba(0,0,255,1.0)'
		})
	}),
	title: '<img src="/legend/Gewaesser.png"/> Gewässer'
});

var group_WMS_NRW = new LayerGroup({
	layers: [
		new TileLayer({
			title: "Digitale Orthofotos",
			type: 'base',
			source: new TileWMS({
				attributions: [
					'Geobasis NRW © <a href="https://www.bezreg-koeln.nrw.de/brk_internet/geobasis/webdienste/geodatendienste/index.html">Digitale Orthophotos</a>',
					ATTRIBUTION
				],
				url: '/geobasis/wms_nw_dop',
				params: {
					'LAYERS': 'nw_dop_rgb',
					'TILED': true
				},
				serverType: 'geoserver'
			})
		}),
		new TileLayer({
			title: "Digitale Topographische Karte",
			type: 'base',
			source: new TileWMS({
				attributions: [
					'Geobasis NRW © <a href="https://www.bezreg-koeln.nrw.de/brk_internet/geobasis/webdienste/geodatendienste/index.html">Digitale Topographische Karte</a>',
					ATTRIBUTION
				],
				url: '/geobasis/wms_nw_dtk',
				params: {
					'LAYERS': 'nw_dtk_col',
					'TILED': true
				},
				serverType: 'geoserver'
			})
		}),
		new TileLayer({
			title: "Open Street Map",
			type: 'base',
			preload: Infinity,
			source: new OSM({
				url: "/osm/{z}/{x}/{y}.png"
			})
		})
	],
	fold: 'open',
	title: "Hintergrundkarten"
});
*/
// G R O U P   L A Y E R S
var group_Grundlagendaten = new LayerGroup({
	layers: [/*lyr_hillshade,*/lyr_Modellgrenze,lyr_GEW],
	title: "Grundlagendaten",
	fold: "open"
});

// variable has to be name "baseLayers" !
var baseLayers = [/*group_WMS_NRW,*/ group_Grundlagendaten];


//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//! Don't change stuff below! !
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

layersList = layersList.concat(baseLayers);


			var lyr_depth_100a = new TileLayer({
			  title: "<i class=\"fa fa-tint\"></i> Max. Wasserstand",
			  name: "depth_100a",
			  description: "Hier werden die maximalen Wasserstände während eines Niederschlagsereignisses dargestellt, das statistisch 1 Mal in 100 Jahren fällt (46,8 l/m² in 60 Minuten). Das entspricht einem Starkregenindex der Stufe 7 gemäß dem Starkregenindex (SRI) für die Stadt Schwerte. Die Wasserstände werden in folgenden Klassen dargestellt:<br/><h3> <i class='fa fa-tint'></i> Max. Wasserstand</h3>                        <span class='legend-entry'><span style='color:#FFFFFF;color:#FFFFFFFF;'>  <i class='fa fa-square'></i></span>  0 cm - 10 cm</span><br>                                                                                                        <span class='legend-entry'><span style='color:#A3DEF5;color:#A3DEF5FF;'>  <i class='fa fa-square'></i></span> 10 cm - 30 cm</span><br>                                                                                                        <span class='legend-entry'><span style='color:#0EA8EF;color:#0EA8EFFF;'>  <i class='fa fa-square'></i></span> 30 cm - 50 cm</span><br>                                                                                                        <span class='legend-entry'><span style='color:#2873A8;color:#2873A8FF;'>  <i class='fa fa-square'></i></span> > 50 cm</span><br><br/><br/>Durch den Starkregenindex (SRI) wird die Bedeutung eines Niederschlagereignisses mir einer einfachen Skalierung von 1 (moderater Starkregen) bis 12 (extremer Starkregen) sowie in einer farblichen Darstellung (grün bis violett) erklärt.<br/>Folgende Abbildung verdeutlicht den Starkregenindex (zum Vergrößern anklicken): <br/><br/><div id='starkregenindex'></div>",
			  group: "Starkregengefahrenkarte",
			  opacity: 1.0,
			  visible: 1,
			  declutter: true,
			  projection: 'EPSG:3857',
			  extent: [835245.6903,6689500.3261,850119.358,6706021.2164],
			  zIndex: 10,
			  legend: [],
			  preload: Infinity,
			  source: new XYZ({
			    url: '/layers/depth_100a/{z}/{x}/{-y}.png'
			  })
			});
			
			var lyr_hillshade = new TileLayer({
			  title: "<i class=\"fa fa-map\"></i> Gelände",
			  name: "hillshade",
			  description: "Eine Schummerung der Geländeoberfläche in 25cm pro Pixel Auflösung. Eine Schummerung ist die plastische Wiedergabe der Geländeformen in einem Graustufenbild. Der räumliche Eindruck entsteht durch die Beleuchtung mit einer imaginären Lichtquelle. Eine Erhebung, welche zur Lichtquelle geneigt ist erscheint hell. Die abgewandte Seite wird dunkel dargestellt. Ebenen sind mit mittlerer Helligkeit gefärbt. Dadurch wird ausgedrückt, wie stark die Oberfläche der Lichtquelle zugewandt ist. Mittels der Schummerung kann die natürliche Geländeform sehr plastisch dargestellt werden. Bewuchs, wie Bäume oder Sträucher, sowie Brücken werden nicht dargestellt.",
			  group: "Grundlagendaten",
			  opacity: 1.0,
			  visible: 1,
			  declutter: true,
			  projection: 'EPSG:3857',
			  extent: [835416.4602127455,6689727.571998208,849874.6223951919,6705958.583597996],
			  zIndex: 8,
			  legend: [],
			  preload: Infinity,
			  source: new XYZ({
			    url: '/layers/hillshade/{z}/{x}/{-y}.png'
			  })
			});
			
			var lyr_abk = new TileLayer({
			  title: "<i class=\"fa fa-atlas\"></i> Amtliche Basiskarte (ABK*)",
			  name: "abk",
			  description: "Als Übergangslösung bis zur Verfügbarkeit der Amtlichen Basiskarte deckt die ABK* den Kartenmaßstab 1:5.000 als topographisches Basiskartenwerk ab. Die ABK* stellt die Schnittstelle zwischen der eigentumsorientierten Liegenschaftskarte und den topographischen Landeskartenwerken dar und wird ausschließlich aus den Informationen des Liegenschaftskatasters (ALKIS) abgeleitet. <br/><a href='https://www.bezreg-koeln.nrw.de/brk_internet/geobasis/lizenzbedingungen_geobasis_nrw.pdf'>Lizensbedingungen</a>",
			  group: "Grundlagendaten",
			  opacity: 1.0,
			  visible: 1,
			  declutter: true,
			  projection: 'EPSG:3857',
			  extent: [835416.4390633554,6689727.537844386,849874.7771020345,6705958.667300866],
			  zIndex: 20,
			  legend: [],
			  preload: Infinity,
			  source: new XYZ({
			    url: '/layers/abk/{z}/{x}/{-y}.png'
			  })
			});
			var group_Starkregengefahrenkarte = new LayerGroup({title:"Starkregengefahrenkarte",fold:"open",layers: [lyr_depth_100a]});
var group_Grundlagendaten = new LayerGroup({title:"Grundlagendaten",fold:"open",layers: [lyr_hillshade,lyr_abk]});
var autoLayers = [group_Starkregengefahrenkarte,group_Grundlagendaten];
layersList = layersList.concat(autoLayers);
	// #### End of layer magic

	var loop = true;
	while(loop) {
	loop = false;
	for(var i in layersList) {
		var layer0 = layersList[i];
		for(var j in layersList) {
			var layer1 = layersList[j];
			if(i == j) {
				continue;
			}
			if(layer0.values_.title == layer1.values_.title) {
				loop = true;
				layersList[i].values_.layers.array_ = layer0.values_.layers.array_.concat(layer1.values_.layers.array_);
				layersList[i].values_.layers.updateLength_();
				layersList.splice(j);
				break;
			}
			if(loop) break;
		}

	}
	}

	var zoom = 13;
	var center = fromLonLat([7.5678,51.4265]);
	var rotation = 0;

	var sb = SideBar(Control);
	//var sideBar = defaultControls().extend([new sb()]);

	const map = new Map({
		controls: defaultControls().extend([
			new ScaleLine({
				units: 'metric'
			}), new sb({})
		]),
		target: 'map',
		layers: layersList,
		loadTilesWhileInteracting: true,
		loadTilesWhileAnimating: true,
		updateVectorsWhileAnimating: true,
		maxTilesLoading: 64,
		view: new View({
			center: center,
			zoom: zoom,
			rotation: rotation,

			projection: 'EPSG:3857',

			minZoom: 11,
			maxZoom: 19
		})
	});



	// #### Start layer switcher
	var layerSwitcher = new LayerSwitcher();
	layerSwitcher.panel = document.getElementById("layers");
	layerSwitcher.setMap(map);

	// #### Start hasher
	//var unregister_sync = sync(map, layerSwitcher);

	// #### Fill infor panel with layer descriptions and legends
	var infopanel = document.getElementById("info-flex"); // use additional flex-boxed container
	function finfo(lyr, idx, a) {
		//console.log(lyr.values_.title, infopanel, lyr, idx, a);
		var name = lyr.values_.name;
		if (name === null) name = lyr.values_.title.replace(/[\W]+/g,"_");

		var desc = lyr.values_.description;
		if (desc === null || typeof desc == "undefined") return;

		var group = lyr.values_.group;
		if (group === null || typeof group == "undefined") group = "";
		else group = "[" + group + "]";
		//<a name=\"" + name + "\"></a>
		var html = "<div id=\""+name+"\" class=\"infopanelentry\"><h3> " + lyr.values_.title + "</h3><h5>" + group + "</h5>";
		html += "<div class=\"legend-description\">" + desc + "</div><br/>";
		var legend = lyr.values_.legend;
		if (legend !== null && typeof legend != "undefined") {
			for (var k in legend) {
				//	console.log(k,legend[k]);
				var l = legend[k];
				var old_color = l.color.substring(0,7); // cut alpha value from hex rgba for old IE
				html += "<span class=\"legend-entry\"><span style=\"color:" + old_color + ";color:" + l.color + ";\">  <i class=\"fa fa-square\"></i></span> >=" + 
l.from 
+ 
" < " + 
l.to 
+ " " + l.unit + "</span><br/>";
			}
		}
		html += "<br/><br/></div>";
		infopanel.innerHTML += html;
	}
	LayerSwitcher.forEachRecursive(map.getLayerGroup(), finfo);


	// const QRCode = await import('qrcode');
	// // #### PDF STUFF
	// var exportButton = document.getElementById('pdf-export');
	// async function onPdfClick() {
	// 	var dims = {
	// 		a0: [1189, 841],
	// 		a1: [841, 594],
	// 		a2: [594, 420],
	// 		a3: [420, 297],
	// 		a4: [297, 210],
	// 		a5: [210, 148]
	// 	};
	//
	// 	exportButton.disabled = true;
	// 	document.body.style.cursor = 'progress';
	// 	//var format = document.getElementById('pdf-format').value;
	// 	var format = document.querySelector('input[name="pdf-format"]:checked').value;
	// 	//var resolution = document.getElementById('pdf-resolution').value;
	// 	var resolution = document.querySelector('input[name="pdf-resolution"]:checked').value;
	// 	var dim = dims[format];
	// 	var width = Math.round(dim[0] * resolution / 25.4);
	// 	var height = Math.round(dim[1] * resolution / 25.4);
	// 	var size = /** @type {module:ol/size~Size} */ (map.getSize());
	// 	var extent = map.getView().calculateExtent(size);
	//
	// 	var href = window.location.href;
	// 	var qrdata = "";
	// 	const QRCode = await import('qrcode');
	// 	const jsPDF = await import('jspdf');
	//
	// 	map.once('rendercomplete', function(event) {
	//
	// 		var canvas = event.context.canvas;
	// 		var data = canvas.toDataURL('image/jpeg');
	// 		var pdf = new jsPDF('landscape', undefined, format);
	// 		pdf.addImage(data, 'JPEG', 0, 0, dim[0], dim[1]);
	// 		QRCode.toDataURL(href, function(err, url) {
	// 			qrdata = url;
	// 			console.log(url);
	// 		});
	// 		pdf.setDrawColor('#FFFFFF');
	// 		pdf.setFillColor('#FFFFFF');
	// 		pdf.rect(0,0,68,20,'F');
	// 		// we use a hidden image element to give us the logo. we already have loaded the logo so it's cool.
	// 		pdf.addImage(document.getElementById('ibblogo_hidden'), 'PNG', 2, 2, 64, 16);
	// 		pdf.addImage(qrdata, 'JPEG', 60, 0, 20, 20);
	// 		pdf.save('map.pdf');
	//
	// 		exportButton.disabled = false;
	// 		document.body.style.cursor = 'auto';
	//
	// 		window.history.go(-1);
	//
	// 		// Reset original map size
	// 		map.setSize(size);
	// 		map.getView().fit(extent, {
	// 			size: size
	// 		});
	// 		window.location.href = href;
	//
	// 	});
	//
	//
	// 	// Set print size
	// 	var printSize = [width, height];
	// 	map.setSize(printSize);
	// 	map.getView().fit(extent, {
	// 		size: printSize
	// 	});
	//
	// };
	// exportButton.addEventListener('click', onPdfClick, false);


	// #### QR-code button press
/*	var qrButton = document.getElementById('qr-button');
	async function onQrClick() {
		console.log("QR for ",window.location.href);
		QRCode.toCanvas(document.getElementById('qr-canvas'), window.location.href, function (error) {
			if (error) console.error(error);
		})
	};
	qrButton.addEventListener('click', onQrClick, false);
*/
	// QRCode.toCanvas(document.getElementById('qr-canvas'), window.location.href, function (error) {
        // 	if (error) console.error("QRCanvas Error: ",error);
	// 	else {
	// 		console.log("QRCanvas INIT SUCCESS");
	// 	}
        // });


	// #### Info panel filter dingens
	function* traverseElements(node) {
		if (node.nodeType != 1) return;
		for (let i = 0; i < node.children.length; i++)
			yield* traverseElements(node.children[i]);
		yield node;
	}
	function findElementWithText(root, text) {
		for (const element of traverseElements(root))
		if (element.textContent.toLowerCase().includes(text))
			return element;
		return null;
	}
	function infofilter(e) {
		var f = e.target;
		var filter = f.value.toLowerCase();
		var filters = filter.split(' ');
		var targets = document.querySelectorAll("div.infopanelentry");
		var any = false;
		if(filter.length) {
			for(var i = 0;i < targets.length;i += 1) {
				var target = targets[i];
				var found = true;
				for(var j = 0;j < filters.length;j += 1) {
					var word = filters[j];
					var r = findElementWithText(target,word);
					if(r === null) {
						found = false;
						break;
					}
				}
				if(found) {
					any = true;
					target.style.display = "";
				} else {
					target.style.display = "none";
				}
			}
		} else {
			for(var i = 0;i < targets.length;i += 1) {
				var target = targets[i];
				target.style.display = "";
			}
		}
	};
	// document.getElementById("infofilter").addEventListener('keyup',infofilter,false);


	document.getElementById('starkregenindex').onclick = () => {
		basicLightbox.create('<div class="sri-image"></div>').show()
	}
}
